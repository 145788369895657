import { sharedLocale }	from "@geotoura/shared/sharedLocale_de";
import { siteInfo }		from "@geotoura/shared/siteInfo_de";

import { Messages } from "@geotoura/nav/locale";

export const messages:Messages = {
	title:			"Reiseziele",
	mainTitle:		"Zur Hauptseite",
	mainUrl:		siteInfo.page.main,
	showRegions:	"Regionen ausklappen",
	hideRegions:	"Regionen einklappen",
	showPrevious:	"Vorheriges Land anzeigen",
	showNext:		"Nächstes Land anzeigen",
	discover:		"entdecken",
	featured:		sharedLocale.page.featured,
	logo:			"logo-DE.svg",
};
