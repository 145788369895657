import { SiteInfo } from "@geotoura/shared/siteInfo";

const wordpressBase	= "rundreisen";

export const siteInfo:SiteInfo	= {
	website:		"https://www.geotoura.com",
	wordpressBase:	wordpressBase,
	email: {
		info:		"info@geotoura.com",
		reisen:		"reisen@geotoura.com",
	},
	phone: {
		human:		"+49 6221 653 079-0",
		machine:	"+4962216530790",
	},
	page: {
		main:			`/${wordpressBase}/`,
		privacyHint:	`/${wordpressBase}/datenschutz-hinweis/`,
		privacy:		`/${wordpressBase}/datenschutz/`,
		newsletter:		`/${wordpressBase}/newsletter/`,
		arb:			`/${wordpressBase}/allgemeine-reisebedingungen/`,
		impressum:		`/${wordpressBase}/impressum/`,
	},
	pdf: {
		privacyHint:	"/content/uploads/Datentschutzhinweis-Geotoura-Stand-16-05-2018.pdf",
		form:			"/content/uploads/Formblatt.pdf",
		arb:			"/content/uploads/arb-geotoura.pdf",
	},
};
