import { Validated }	from "ts-base/validated";
import { Validator }	from "ts-base/validation/validator";
import * as JS			from "ts-base/validation/json";

import { Logger }		from "@geotoura/shared/logger";

const logger	= Logger.create("i18n");

// the LanguageCode is set in header.php derived from the wordpress get_bloginfo('language')
export type LanguageCode	= "de" | "en" | "it" ;

export namespace LanguageCode {
	const defaultCode:LanguageCode	= "de";

	export const unsafeOf	= (it:string):LanguageCode	=> it as LanguageCode;

	export const valueOrDefault	= (raw:string|null):LanguageCode	=> {
		if (raw === null) {
			logger.error(`No language was received, so default language ${defaultCode} is chosen`);
			return defaultCode;
		}

		if (!(raw === "de" || raw === "en" || raw === "it" )) {
			logger.error(`Unexpected language ${raw} was received, so default language ${defaultCode} is chosen`);
			return defaultCode;
		}

		return raw;
	};

	// attention: uses valueOrDefault
	const validateString:Validator<string, LanguageCode>	=
		s => Validated.valid(valueOrDefault(s));

	export const json	= Validated.andThen(JS.string, validateString);
	export const query	= validateString;
}

/*
logger.info("lang", navigator.language);
logger.info("langs", navigator.languages);
const lang = navigator.language.startsWith("de") ? "de" : "en";
*/
