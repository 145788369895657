import { Json }				from "ts-base/json";
import { UrlQuery }			from "ts-base/web/urlQuery";

import { Logger }			from "@geotoura/shared/logger";
import * as commonModel		from "@geotoura/shared/commonModel";
import * as settingsModel	from "@geotoura/shared/settingsModel";
import * as upModel			from "@geotoura/shared/upModel";
import * as navModel		from "@geotoura/shared/navModel";
import * as routeModel		from "@geotoura/shared/routeModel";
import * as floaterModel	from "@geotoura/shared/floaterModel";
import * as fbModel			from "@geotoura/shared/fbModel";
import * as buchungModel	from "@geotoura/shared/buchungModel";
import * as traveldataModel	from "@geotoura/shared/traveldataModel";

const logger	= Logger.create("Server");

//-----------------------------------------------------------------------------
// cookie warning
//-----------------------------------------------------------------------------

export const setSettings	= (data:settingsModel.SettingsData):Promise<settingsModel.RequestResponse> =>
	postData("/api/settings", data);

//-----------------------------------------------------------------------------
// buchung
//-----------------------------------------------------------------------------

export const getOffer	= (request:buchungModel.OfferRequest):Promise<buchungModel.OfferResponse> =>
	callServer("/api/offer", request);

export const setBooking	= (data:buchungModel.BookingRequest):Promise<buchungModel.BookingResponse> =>
	postData("/api/book", data);

export const setChange	= (data:buchungModel.ChangeRequest):Promise<buchungModel.ChangeResponse> =>
	postData("/api/change", data);

//-----------------------------------------------------------------------------
// fb
//-----------------------------------------------------------------------------

export const getQuestionnaire	= (request:fbModel.QuestionnaireRequest):Promise<fbModel.Questionnaire> =>
	callServer("/api/questionnaire", request);

export const postQuestionnaire	= (data:fbModel.AnswersRequest):Promise<{ return:string }>	=>
	postData("/api/answers", data);

//-----------------------------------------------------------------------------
// floater
//-----------------------------------------------------------------------------

export const setInquiry	= (data:floaterModel.InquiryData):Promise<floaterModel.RequestResponse> =>
	postData("/api/inquiry", data);

export const setMeeting	= (data:floaterModel.MeetingData):Promise<floaterModel.RequestResponse> =>
	postData("/api/meeting", data);

//-----------------------------------------------------------------------------
// nav
//-----------------------------------------------------------------------------

export const getNav	= (request:navModel.NavRequest):Promise<navModel.NavData> =>
	callServerCached("/api/nav", request);

//-----------------------------------------------------------------------------
// up
//-----------------------------------------------------------------------------

export const getData	= (request:upModel.UpDataRequest):Promise<upModel.RawUpData> =>
	callServerCached("/api/up_data", request);

export const getDestination	= (request:upModel.UpDestinationRequest):Promise<upModel.DesktopDestination> =>
	callServerCached("/api/up_destination", request);

export const getRoute	= (request:upModel.UpRouteRequest):Promise<upModel.RouteWithWarnings> =>
	callServer("/api/up_route", request);

export const setRequest	= (data:upModel.RequestData):Promise<upModel.RequestResponse> =>
	postData("/api/up_request", data);

//-----------------------------------------------------------------------------
// floater
//-----------------------------------------------------------------------------

export const getRouteName	= (request:floaterModel.RouteNameRequest):Promise<commonModel.RouteName> =>
	callServerCached("/api/routename", request);

// NOTE: there is also "/api/territorybox" called by wordpress server side

//-----------------------------------------------------------------------------
// route: generate HTML for wordpress exampleroute pages
//-----------------------------------------------------------------------------

export const getCopyRoute	= (request:routeModel.CopyRouteRequest):Promise<routeModel.Route> =>
	callServer("/api/copyroute", request);

//-----------------------------------------------------------------------------
// traveldata
//-----------------------------------------------------------------------------

export const setTraveldata	= (data:traveldataModel.RequestData):Promise<traveldataModel.RequestResponse> =>
	postData("/api/traveldata", data);

export const getTravelDataRoute	= (request:traveldataModel.RouteRequest):Promise<traveldataModel.Route> =>
	callServer("/api/traveldata_route", request);

//-----------------------------------------------------------------------------
//## helper

const cache	= new Map<string, unknown>();

// get requests, but the result is cached forever
const callServerCached	= <T>(baseUrl:string, parameters:UrlQuery):Promise<T> =>
	new Promise((resolve, reject) => {
		// NOTE sorting the query string parameters is important to get stable cache keys
		const key	= baseUrl + UrlQuery.fullString(parameters, "non-empty", "sorted");
		if (cache.has(key)) {
			resolve(cache.get(key) as T);
		}
		else {
			callServer<T>(baseUrl, parameters)
			.then((result) => {
				cache.set(key, result);
				resolve(result);
			})
			.catch((e) =>
				// eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
				reject(e)
			);
		}
	});

// get requests
const callServer	= <T>(baseUrl:string, parameters:UrlQuery):Promise<T> =>
	new Promise((resolve, reject) => {
		const target	= baseUrl + UrlQuery.fullString(parameters, "non-empty", "unsorted");

		const request = new XMLHttpRequest();
		request.open("GET", target, true);

		request.onload = () => {
			if (request.status >= 200 && request.status < 400) {
				resolve(JSON.parse(request.responseText));
			} else {
				// eslint-disable-next-line @typescript-eslint/no-base-to-string
				logger.error(`Request Failed with status ${request.status}: ${request.toString()}`);
				// eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
				reject(request);
			}
		};
		request.onerror = () => {
			// eslint-disable-next-line @typescript-eslint/no-base-to-string
			logger.error(`Request Failed: ${request.toString()}`);
			// eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
			reject(request);
		};
		request.send();
	});

// post json requests
const postData	= <T>(url:string, data:Json):Promise<T> =>
	new Promise((resolve, reject) => {
		const request = new XMLHttpRequest();
		request.open("POST", url, true);
		request.onreadystatechange = () => {
			if (request.readyState >= 4) {
				if (request.status === 200) {
					resolve(JSON.parse(request.responseText));
				}
				else {
					// eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
					reject(request);
				}
			}
		};
		request.setRequestHeader("X-Requested-With",	"XMLHttpRequest");
		request.setRequestHeader("Content-Type",		"application/json; charset=UTF-8");
		request.send(Json.stringify(data));
	});
