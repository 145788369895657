import { sharedLocale }	from "@geotoura/shared/sharedLocale_it";
import { siteInfo }		from "@geotoura/shared/siteInfo_it";

import { Messages } from "@geotoura/nav/locale";

export const messages:Messages = {
	title:			"Viaggi individuali",
	mainTitle:		"Alla pagina principale",
	mainUrl:		siteInfo.page.main,
	showRegions:	"Mostra le regioni",
	hideRegions:	"Nascondi le regioni",
	showPrevious:	"Mostra il paese precedente",
	showNext:		"Mostra il prossimo paese",
	discover:		"Scoprite",
	featured:		sharedLocale.page.featured,
	logo:			"logo-IT.svg",
};
