import { Logger as BaseLogger } from "ts-base/logger";

// import { env }	from "@geotoura/backend/env";

export type Logger	= BaseLogger;

export const Logger	= {
	create:	(tag:string):Logger	=>
		BaseLogger.create({
			tag:			tag,
			minimumLevel:	"debug",
			withTimestamp:	true,
			// minimumLevel:	env.log.level,
			// withTimestamp:	env.log.timestamp,
		}),
};
