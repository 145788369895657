import { JSX, render }	from "preact";
import { useState }		from "preact/hooks";

import { Fn }			from "ts-base/fn";
import { Endo }			from "ts-base/endo";

export type Upd<T> = (change:Endo<T>) => void;

export const connect = <T,>(container:Element|Document, initial:T, app:Fn<T, JSX.Element>):Upd<T> => {
	let refresh:Upd<T>|null	= null;

	const Root	= ():JSX.Element	=> {
		const [ model, setModel ]	= useState(initial);
		refresh	= setModel;
		return app(model);
	};

	render(<Root/>, container);

	return refresh as unknown as Upd<T>;
};
