import { Branded }		from "ts-base/branded";
import { Validated }	from "ts-base/validated";
import { Validator }	from "ts-base/validation/validator";
import * as JS			from "ts-base/validation/json";

// non-empty
export type CountryId	= Branded<string, "CountryId">;

export namespace CountryId {
	export const brand	= Branded.brand<CountryId>;

	export const fromString	= (s:string):CountryId|null	=>
		s !== "" ? brand(s) : null;

	const validateString:Validator<string, CountryId>	=
		Validator.fromNullResult(fromString, "expected a valid country id");

	export const json	= Validated.andThen(JS.string, validateString);
	export const query	= validateString;
}
