/* eslint-disable @stylistic/comma-dangle */

import { SharedLocale }	from "@geotoura/shared/sharedLocale";
import { siteInfo }		from "@geotoura/shared/siteInfo_it";

const page	= {
	privacyHint: {
		href:	siteInfo.page.privacyHint,
		text:	"l'informativa sulla protezione dei dati"
	},
	featured:	[],
};

export const sharedLocale:SharedLocale	= {
	phone: {
		href:	`tel:${siteInfo.phone.machine}`,
		text:	siteInfo.phone.human
	},
	email: {
		info:	{
			href:	`mailto:${siteInfo.email.info}`,
			text:	siteInfo.email.info
		},
		reisen:	{
			href:	`mailto:${siteInfo.email.reisen}`,
			text:	siteInfo.email.reisen
		}
	},
	page: page,
	privacyAgreement: {
		pre:	"Letto ",
		link:	page.privacyHint,
		post:	"e accettato."
	}
};
