import { Endo }		from "ts-base/endo";
import { Zoomer }	from "ts-base/zoomer";

import { Logger }		from "@geotoura/shared/logger";
import * as i18n		from "@geotoura/shared/i18n";
import * as commonModel	from "@geotoura/shared/commonModel";

import * as connect	from "@geotoura/common/util/connect";
import * as Server	from "@geotoura/common/Server";

import { Model, AppModel }	from "@geotoura/nav/model";

const logger	= Logger.create("nav-actions");

//-----------------------------------------------------------------------------
//## refresh

let handle:connect.Upd<Model>|null	= null;

export const setRefresh	= (it:connect.Upd<Model>):void => { handle = it; };

const refresh:connect.Upd<Model>	= (change:Endo<Model>):void => {
	if (handle === null)	throw new Error("refresh was called before setRefresh");
	handle(change);
};

//-----------------------------------------------------------------------------

const modelZoomer	=
	Zoomer.on<Model>();

const appOrNullZoomer:Zoomer<Model, AppModel|null>	=
	modelZoomer.atKey("app");

const appZoomer:Zoomer<Model, AppModel>	=
	appOrNullZoomer.notNull();

const selectedTerritoryIdZoomer:Zoomer<Model, commonModel.TerritoryId|null>	=
	appZoomer.atKey("selectedTerritoryId");

// TODO nav replace with own api call with only needed data
export const getData	= async (languageCode:i18n.LanguageCode):Promise<void> => {
	try {
		const json	= await Server.getNav({ lang: languageCode });

		refresh(
			appOrNullZoomer.set({
				data:					json,
				selectedTerritoryId:	null,
			})
		);
	}
	catch (e) {
		logger.error("Could not load data, ", e);
	}
};

export const toggleMenu = ():void =>
	refresh(
		Zoomer.on<Model>().atKey("menu").mod(it =>!it)
	);

export const toggleTerritory = (territoryId:commonModel.TerritoryId):void =>
	refresh(
		selectedTerritoryIdZoomer.mod((current) => current !== territoryId ? territoryId : null)
	);

export const selectTerritory = (territoryId:commonModel.TerritoryId):void =>
	refresh(
		selectedTerritoryIdZoomer.set(territoryId)
	);
