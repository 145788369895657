/* eslint-disable @stylistic/comma-dangle */

import { SharedLocale }	from "@geotoura/shared/sharedLocale";
import { siteInfo }		from "@geotoura/shared/siteInfo_de";

const page	= {
	privacyHint: {
		href:	siteInfo.page.privacyHint,
		text:	"Datenschutz-Hinweis"
	},
	featured: [
		{
			href:	`/${siteInfo.wordpressBase}/geotoura-reiselabor/`,
			text:	"Reiselabor"
		},
		{
			href:	`/${siteInfo.wordpressBase}/reisen-mit-dem-eigenen-auto/`,
			text:	"Touren mit dem eigenen Auto"
		},
	],
};

export const sharedLocale:SharedLocale	= {
	phone: {
		href:	`tel:${siteInfo.phone.machine}`,
		text:	siteInfo.phone.human
	},
	email: {
		info:	{
			href:	`mailto:${siteInfo.email.info}`,
			text:	siteInfo.email.info
		},
		reisen:	{
			href:	`mailto:${siteInfo.email.reisen}`,
			text:	siteInfo.email.reisen
		}
	},
	page: page,
	privacyAgreement: {
		pre:	"Den ",
		link:	page.privacyHint,
		post:	" habe ich gelesen und akzeptiere ihn."
	}
};
