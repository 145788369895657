import { JSX }	from "preact";

import { classes }	from "ts-base/web/dom";

import * as wpModel	from "@geotoura/shared/wpModel";

export type DesktopListProps	= Readonly<{
	data:	wpModel.NavData,
}>;

export const DesktopList = ({ data }:DesktopListProps):JSX.Element =>
	<>
		<div class={classes(
				"nav-desktop-area",
				menuVersion(data)
		)}>
			<ul class="nav-desktop-territories">
				{ data.territories.map((t) =>
					territory(
						t,
						data.regions.filter((r) =>
							r.territoryId === t.id && r.name !== t.name
						)
					)
				)}
			</ul>
		</div>
		<div class="nav-desktop-backdrop"></div>
	</>;

const territory = (t:wpModel.Territory, regions:ReadonlyArray<wpModel.Region>):JSX.Element =>
	<li class="nav-desktop-territory">
		<a href={t.url}>{t.name}</a>
		<ul class="nav-desktop-regions">
			{ regions.map(region) }
		</ul>
		<div class="nav-desktop-territory-description">
			{description(t, "t")}
		</div>
	</li>;

const region = (r:wpModel.Region):JSX.Element =>
	<li class="nav-desktop-region">
		<a href={r.url}>{r.name}</a>
		<div class="nav-desktop-region-description">
			{description(r, "u")}
		</div>
	</li>;

const description = (data:wpModel.Region|wpModel.Territory, type:"t"|"u"):JSX.Element =>
	<div class="nav-desktop-description animated-05 fadeIn">
		<div class="nav-description-image" style={{ backgroundImage: `url("/p/${type}/460/${data.id}_h.jpg")` }}>
			<div class="nav-description-title">{data.name}</div>
			<div class="nav-description-portrait" style={{ backgroundImage: `url("/p/${type}/460/${data.id}_e.jpg")` }}></div>
		</div>
		<div class="nav-description-expert-role">{data.expertRole}</div>
		<div class="nav-description-expert-name">{data.expertName}</div>
		<div class="nav-description-text">{data.expertQuote}</div>
	</div>;

// Cover the case that the users screen is not high enough to display the full navigation menu with submenus
// We calculate the worst case height.
// If it is larger than the users window.height, we dont display the regions and the territory list gets a scrollbar
const menuVersion = (data:wpModel.NavData):"full"|"restricted" => {
	// Hardocded itemHeight, result of 20px top/botton-Padding + 16px font-size + 3px borders
	const listItemHeight	= 59;
	// Hardcoded, added height of headerbar and @nav-desktop-height
	const headerOffset		= 136;

	// calculate sum of territoryposition and count of regions
	// take maximum
	const territoryList = data.territories;
	const regionList = data.regions;

	const territoryRegionsCount = territoryList.map((trc, index) => {
		const territoryPosition = index + 1;
		const regionCount = regionList.filter(rc => rc.territoryId === trc.id);
		return territoryPosition + regionCount.length - 1;
	});
	const maxItemLength = Math.max(...territoryRegionsCount);

	const criticalHeight = headerOffset + listItemHeight * maxItemLength;

	return criticalHeight > window.innerHeight ? "restricted" : "full";
};
