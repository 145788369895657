import { JSX }	from "preact";

import * as wpModel		from "@geotoura/shared/wpModel";
import * as commonModel	from "@geotoura/shared/commonModel";

import * as actions		from "@geotoura/nav/actions";
import { useMessages }	from "@geotoura/nav/useMessages";
import { AppModel }		from "@geotoura/nav/model";

export type MobileListProps	= Readonly<{
	app:	AppModel,
}>;

export const MobileList = ({ app }:MobileListProps):JSX.Element => {
	const msg = useMessages();

	return (
		<ul class="nav-mobile-territories">
			{ app.data.territories.map((t) =>
				territory(
					t,
					app.data.regions.filter((r) =>
						r.territoryId === t.id && r.name !== t.name
					),
					app.selectedTerritoryId,
					msg.hideRegions,
					msg.showRegions
				)
			)}
		</ul>
	);
};

const territory = (
		t:wpModel.Territory,
		regions:ReadonlyArray<wpModel.Region>,
		selectedTerritoryId:commonModel.TerritoryId|null,
		hide:string,
		show:string
	):JSX.Element =>
	<li class="nav-mobile-territory">
		<div class={`nav-mobile-territory-inner ${t.id === selectedTerritoryId ? "active" : ""}`}>
			<a class="nav-mobile-link" href={t.url}>{t.name}</a>
			{ regions.length > 0 &&
				<button class="nav-button" onClick={() => actions.toggleTerritory(t.id)}>
					<div class={`fa-light fa-chevron-${ t.id === selectedTerritoryId ? "down" : "right"}`}></div>
					<span class="sr-only">{t.id === selectedTerritoryId ? hide : show}</span>
				</button>
			}
		</div>
		{ t.id === selectedTerritoryId &&
			<ul class="nav-mobile-regions">
				{ regions.map(region) }
			</ul>
		}
	</li>;

const region = (r:wpModel.Region):JSX.Element =>
	<li class="nav-mobile-region">
		<a class="nav-mobile-link" href={r.url}>{r.name}</a>
	</li>;
