import { JSX }	from "preact";

import { classes }	from "ts-base/web/dom";

import { useMessages }	from "@geotoura/nav/useMessages";
import { Model }		from "@geotoura/nav/model";
import { TabletList }	from "@geotoura/nav/TabletList";
import * as actions		from "@geotoura/nav/actions";

export type TabletProps	= Readonly<{
	model:	Model,
}>;

export const Tablet = ({ model }:TabletProps):JSX.Element => {
	const msg = useMessages();

	return (
		<>
			<div class={classes(
				"nav-inner",
				model.screenSize
			)}>
				<div class="nav-menu">
					<div class="nav-menu-item nav-desktop-opener">
						<button
							class={classes(
								"nav-menu-item-link",
								model.menu && "active"
							)}
							onClick={() => actions.toggleMenu()}
						>
							<span>{msg.title}</span>
						</button>
					</div>
					{
						msg.featured.map(it =>
							<div class="nav-menu-item">
								<a class="nav-menu-item-link" href={it.href}><span>{it.text}</span></a>
							</div>
						)
					}
				</div>
				<a title={msg.mainTitle} href={msg.mainUrl} aria-label={msg.mainTitle}>
					<img class="nav-logo" src={`/img/common/${msg.logo}`} alt="Geotoura-Logo"/>
				</a>
			</div>
			<div class={classes(
				"nav-tablet-area",
				model.menu && "open"
			)}>
				{ model.app !== null && model.menu &&
					<TabletList app={model.app}/>
				}
			</div>
		</>
	);
};
