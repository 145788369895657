import { sharedLocale }	from "@geotoura/shared/sharedLocale_en";
import { siteInfo }		from "@geotoura/shared/siteInfo_en";

import { Messages } from "@geotoura/nav/locale";

export const messages:Messages = {
	title:			"Destinations",
	mainTitle:		"To the main page",
	mainUrl:		siteInfo.page.main,
	showRegions:	"Show regions",
	hideRegions:	"Hide regions",
	showPrevious:	"Show previous country",
	showNext:		"Show next country",
	discover:		"discover",
	featured:		sharedLocale.page.featured,
	logo:			"logo-DE.svg",
};
