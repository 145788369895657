import { createContext }	from "preact";
import { useContext }		from "preact/hooks";

import { Messages }			from "@geotoura/nav/locale";

// "de" is the default, this is overwritten soon
const Context	= createContext<Messages>(Messages.of["de"]);

export const ProvideMessages	= Context.Provider;

export const useMessages		= ():Messages => useContext(Context);
