import { SiteInfo } from "@geotoura/shared/siteInfo";

const wordpressBase	= "viaggi";

// TODO i18n get correct data for italian website
export const siteInfo:SiteInfo = {
	website:		"https://www.geotoura.it",
	wordpressBase:	wordpressBase,
	email: {
		info:		"info@geotoura.it",
		reisen:		"info@geotoura.it",
	},
	phone: {
		human:		"0942 264 463",
		// The "0" is dialed in italian international numbers, contrary to practice in other countries
		machine:	"+390942264463",
	},
	page: {
		main:			`/${wordpressBase}/`,
		privacyHint:	`/${wordpressBase}/informativa-sulla-protezione-dei-dati/`,
		privacy:		`/${wordpressBase}/protezione-dei-dati/`,
		newsletter:		`/${wordpressBase}/newsletter/`,
		arb:			`/${wordpressBase}/condizioni-generali-di-viaggio/`,
		impressum:		`/${wordpressBase}/note-legali/`,
	},
	pdf: {
		privacyHint:	"/content/uploads/informativa-sulla-protezione-dei-dati.pdf",
		form:			"/content/uploads/modulo.pdf",
		arb:			"/content/uploads/arb.pdf",
	},
};
