import { createContext }	from "preact";
import { useContext }		from "preact/hooks";

import * as i18n	from "@geotoura/shared/i18n";

// "de" is the default, this is overwritten soon
const Context	= createContext<i18n.LanguageCode>("de");

export const ProvideLanguageCode	= Context.Provider;

export const useLanguageCode	= ():i18n.LanguageCode => useContext(Context);
