import { JSX }	from "preact";

import * as i18n	from "@geotoura/shared/i18n";

import { ProvideLanguageCode }	from "@geotoura/common/util/useLanguageCode";

import { ProvideMessages }	from "@geotoura/nav/useMessages";
import { Messages }			from "@geotoura/nav/locale";
import { Model }			from "@geotoura/nav/model";
import { Desktop }			from "@geotoura/nav/Desktop";
import { Tablet }			from "@geotoura/nav/Tablet";
import { Mobile }			from "@geotoura/nav/Mobile";

export type AppProps	= Readonly<{
	languageCode:	i18n.LanguageCode,
	messages:		Messages,
	model:			Model,
}>;

// The Desktop Navigation is only used when the device is hover-capable, otherwise we fallback to Tablet

export const App = ({ languageCode, messages, model }:AppProps):JSX.Element =>
	<ProvideLanguageCode value={languageCode}>
	<ProvideMessages value={messages}>
		{	model.screenSize === "mobile"
			? <Mobile model={model}/>
			: model.canHover && model.screenSize === "desktop"
			? <Desktop model={model}/>
			: <Tablet model={model}/>
		}
	</ProvideMessages>
	</ProvideLanguageCode>;
