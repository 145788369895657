import * as sharedLocale	from "@geotoura/shared/sharedLocale";
import * as i18n			from "@geotoura/shared/i18n";

import { messages as de } from "@geotoura/nav/locale_de";
import { messages as en } from "@geotoura/nav/locale_en";
import { messages as it } from "@geotoura/nav/locale_it";

export type Messages = Readonly<{
	title:			string,
	mainTitle:		string,
	mainUrl:		string,
	showRegions:	string,
	hideRegions:	string,
	showPrevious:	string,
	showNext:		string,
	discover:		string,
	featured:		ReadonlyArray<sharedLocale.Link>,
	logo:			string,
}>;

export namespace Messages {
	export const of:i18n.Localized<Messages>	= { de, en, it };
}
